// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'

import logo from './assets/legacy_logo.png'
import img1 from './assets/img1.jpg'
import img2 from './assets/img2.jpg'
import img3 from './assets/img3.jpg'
import lady from './assets/lady.jpg'

import { Avatar } from '@chakra-ui/react'

import { FiBell } from 'react-icons/fi';
import { FiChevronRight } from 'react-icons/fi'
import {AiOutlineDownload } from 'react-icons/ai'



// HEAD Grey #6A6E73 (Standard Grey) = text-gray-500
// HEader hover #111820 (Standard Black) = text-gray-800
// LOGO Green #23C672 
// DIVIDER Grey #DCE1E6 
// Background Grey #F7F8F9 

// Button Blue (Before hover): #288DCC
    // After Hover: #


const HEADER_LINK="font-semibold text-sm text-gray-500 hover:text-gray-800"

function Header() {
  return (
    <div className="flex flex-row h-[64px] border-b border-t-0 justify-between shadow"
    >
      <div className='w-full flex flex-row justify-between px-[20px] shadow-md shadow-gray-300/40 pt-[2px] pb-[1px]'>
        <img src={logo} alt="Legacy Logo" />
        <div className="flex flex-row items-center gap-6 ">
          <span className={HEADER_LINK}>Opportunities</span>
          <span className={HEADER_LINK}>Portfolio</span>
          <span className={HEADER_LINK}>Investments</span>
          <span className={HEADER_LINK}>Accounts</span>
          <span className={HEADER_LINK}>Documents</span>
          <FiBell size={25} />
          <Avatar
            size='sm'
            name='Carter Bastian'
            src='https://bit.ly/tioluwani-kolawole'
          />{' '}
        </div>
      </div>
    </div>
  )
}

const breadCrumb = (
  <div className="flex flex-row gap-3 items-center text-md py-6">
    <span className="text-gray-500">Opportunities</span>
    <FiChevronRight className="text-gray-400" size={20} />
    <span className="text-gray-500">Legacy Northwest Fund</span>
  </div>
)

const IMG_STYLE="h-[380px] w-[calc(33.34%-.166rem)] flex-shrink object-cover"
const imgs = (
  <div className="flex flex-row w-full gap-1">
    <img className={`${IMG_STYLE} rounded-l-md`} src={img1} alt="img1" />
    <img className={IMG_STYLE} src={img2} alt="img2" />
    <img className={`${IMG_STYLE} rounded-r-md`} src={img3} alt="img3" />
  </div>
)

const TABLE_H="font-semibold text-gray-500 text-sm"
const TABLE_P="font-bold text-gray-800 text-md"
const tableTitle = (
  <div className="flex flex-row mb-10 items-center">
    <h2 className="text-3xl font-bold mr-3">Legacy Northwest Fund</h2>
    <div className="bg-[#F9DCEB] text-center  text-xs font-bold text-[#BE1F76] rounded-xl px-2 py-[2px] mt-1">
      Open
    </div>
  </div>
)
const fundTable = (
  <div className="flex flex-col pb-4">
    { tableTitle }
    <div className="flex flex-row flex-wrap mt-1">
      <div className="w-1/2 mb-8">
        <h3 className={TABLE_H}>INCEPTION DATE</h3>
        <p className={TABLE_P}>01/01/2008</p>
      </div>
      <div className="w-1/2 pl-3 mb-8">
        <h3 className={TABLE_H}>PROPERTY TYPE</h3>
        <p className={TABLE_P}>Single Family</p>
      </div>
      <div className="w-1/2 mb-8">
        <h3 className={TABLE_H}>STRATEGY</h3>
        <p className={TABLE_P}>Private Lending</p>
      </div>
      <div className="w-1/2 pl-3 mb-8">
        <h3 className={TABLE_H}>INVESTMENT TYPE</h3>
        <p className={TABLE_P}>Income</p>
      </div>
      <div className="w-1/2 mb-8">
        <h3 className={TABLE_H}>RISK PROFILE</h3>
        <p className={TABLE_P}>Value Add</p>
      </div>
      <div className="w-1/2 pl-3 mb-8">
        <h3 className={TABLE_H}>PROGRAM TYPE</h3>
        <p className={TABLE_P}>Open-Ended Fund</p>
      </div>
    </div>

  </div>
)

const opportunityDescription = (
  <div className="flex flex-col pt-12">
    <h2 className="text-2xl font-bold mb-6">Opportunity description</h2>
    <p className="pb-6">
      This is a low-resolution clone of the Verivest platform created
      by the Check
      Investor Status team to demo what an integreation
      with our product would look like.
      {' '}
      <span className="font-bold">This is not real the verivest platform.</span> 
      {' '}
      Please click
      the "Get Accredited" button to the right to demo the accreditation
      integration. 
      Let us know if and when you&apos;d like us to take this demo down and we&apos;ll happily
      remove it.

    </p>
    <iframe
      className="h-[510px] pb-12 rounded"
      src='https://www.youtube.com/embed/hklDxzmoBkQ'
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
    <div />
  </div>
)
const documents = (
  <div className="flex flex-col py-12">
    <h2 className="text-2xl font-bold mb-6">Documents</h2>
    <p className="pb-6">
      The documents below contain more information about Check Investor Status.
      We&apos;ve included a product one-pager, a document with some ideas, and a letter to your
      team from our
      founders. You can get in contact at 
      {' '}
      <a className="text-[#288DCC] hover:font-semibold" href="mailto:carter@checkinvestorstatus.com">carter@checkinvestorstatus.com</a> 
      .
    </p>
    <div className="flex flex-col gap-4 pb-4">
      <a 
        className="rounded shadow bg-white w-full border py-4 px-6 flex flex-row justify-between items-center"
          href="/check-investor-status-product-one-pager.pdf"
          target="_blank"
      >
        <div className="flex flex-row items-end gap-4">
          <div className="px-2 py-1 text-xs font-semibold bg-[#A1A5AB] text-white text-center rounded">PDF</div>
          <p className="text-left">Check Investor Status - Product One Pager.pdf</p>
        </div>
        <div className="flex flex-row items-center">
          <p className="text-left text-gray-500 pr-3">01/30/2022</p>
          <AiOutlineDownload className="text-[#288DCC]" size={25} />
        </div>
      </a>
      <a 
        className="rounded shadow bg-white w-full border py-4 px-6 flex flex-row justify-between items-center"
          href="/check-investor-status-verivest-ideas.pdf"
          target="_blank"
      >
        <div className="flex flex-row items-end gap-4">
          <div className="px-2 py-1 text-xs font-semibold bg-[#A1A5AB] text-white text-center rounded">PDF</div>
          <p className="text-left">Check Investor Status - Verivest Ideas.pdf</p>
        </div>
        <div className="flex flex-row items-center">
          <p className="text-left text-gray-500 pr-3">01/30/2022</p>
          <AiOutlineDownload className="text-[#288DCC]" size={25} />
        </div>
      </a>
    </div>
  </div>
)

const lCol = (
  <div className="flex flex-col w-[calc(66.7%-.11rem)] pr-4 border-blue-500 divide-y">
    { fundTable }
    { opportunityDescription }
    { documents }
    <div className="py-12" />
  </div>
)

const sponsoredBy = (
  <div className="flex flex-row mt-8">
    <Avatar
      size='md'
      name='Some Sponsor'
      src={lady}
    />
    <div className="flex flex-col ml-4">
      <p className="text-gray-800">Sponsored by: <span className="font-semibold">Legacy Capital Group</span></p>
      <p className="text-[#288DCC] font-semibold">Contact sponsor</p>
    </div>
  </div>
)
const rCol = (
  <div className="flex flex-col w-[calc(33.34%-.11rem)] pl-2 h-24 border-green-600 sticky top-6">
    <div className="flex flex-col w-full  bg-white rounded shadow-lg px-6 py-6">
      <h2 className="text-xs font-semibold text-gray-500">DEBT</h2>
      <div className="flex flex-col divide-y mt-9">
        <div className="flex flex-row justify-between w-full py-3 items-center">
          <h3 className="text-sm text-gray-500">Minimum</h3>
          <h3 className="text-sm font-semibold text-gray-900">$50k</h3>
        </div>
        <div className="flex flex-row justify-between w-full py-3 items-center">
          <h3 className="text-sm text-gray-500">Note Rate</h3>
          <h3 className="text-sm font-semibold text-gray-900">&mdash;</h3>
        </div>
        <div className="flex flex-row justify-between w-full py-3 items-center">
          <h3 className="text-sm text-gray-500">Min. Subordinated Equity</h3>
          <h3 className="text-sm font-semibold text-gray-900">&mdash;</h3>
        </div>
        <div className="flex flex-row justify-between w-full py-3 items-center">
          <h3 className="text-sm text-gray-500">Hold Period</h3>
          <h3 className="text-sm font-semibold text-gray-900">24mo</h3>
        </div>
        <div className="flex flex-row justify-between w-full py-3 items-center">
          <h3 className="text-sm text-gray-500">Distribution Frequency</h3>
          <h3 className="text-sm font-semibold text-gray-900">Quarterly</h3>
        </div>
      </div>
      <a 
        className="rounded bg-[#288DCC] hover:bg-[#255994] w-full mt-3 py-3 text-md text-white text-center font-semibold"
        href="https://www.checkinvestorstatus.com/verivest/get-accredited/legacyId?sponsorName=Legacy%20Capital%20Group"
        target="_blank" rel="noreferrer"
      >
        Get Accredited
      </a>
    </div>
    { sponsoredBy }
  </div>
)

const content = (
  <div className="flex flex-row w-full justify-between mt-8">
    {lCol}
    {rCol}
  </div>
)

function App() {
  return (
    <ChakraProvider>
      <div className="container-fluid">
        <Header />
        <div className="bg-[#F7F8F9] min-w-screen">
          <div className="flex flex-col max-w-[1280px] border-red-500 mx-auto">
            {breadCrumb}
            {imgs}
            {content}
          </div>

        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
